import * as React from "react";
import { useContext, useCallback } from "react";
import { Button, useTheme, TextInput, HelperText } from "react-native-paper";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { RegistrationContext } from "Contexts/Registration";
import { Platform, ScrollView } from "react-native";
import Auth from "@aws-amplify/auth";
import ScreenBottom from "Components/Screen/Bottom";
import Screen from "Components/Screen";
import Stepper from "Components/Stepper";
import moment from "moment";
import { STATES } from "shared/Config";

const canadianProvinces = [
  ["BC", "British Columbia"],
  ["ON", "Ontario"],
  ["NL", "Newfoundland and Labrador"],
  ["NS", "Nova Scotia"],
  ["PE", "Prince Edward Island"],
  ["NB", "New Brunswick"],
  ["QC", "Quebec"],
  ["MB", "Manitoba"],
  ["SK", "Saskatchewan"],
  ["AB", "Alberta"],
  ["NT", "Northwest Territories"],
  ["NU", "Nunavut"],
  ["YT", "Yukon Territory"],
];

// const scrollEnabled = Platform.select({ web: true, default: false });

const schema = yup.object().shape({
  address: yup
    .string()
    .min(2, "Too short - should be 2 chars minimum.")
    .max(55, "Too long - should be 55 chars maximum.")
    .required("Can't be blank"),
  city: yup
    .string()
    .min(2, "Too short - should be 2 chars minimum.")
    .max(25, "Too long - should be 25 chars maximum.")
    .matches(/^[a-z ,.'-]+$/i, "Must be a valid last name")
    .required("Can't be blank"),
  state: yup
    .string()
    .matches(/^[A-Z][A-Z]$/, "Must be a valid state abbrev")
    .when("country", country =>
      yup
        .string()
        .oneOf(
          country === "CA"
            ? canadianProvinces.map(([abbrev, _name]) => abbrev)
            : STATES.map(([_name, abbrev]) => abbrev),
          "Must be a valid state abbrev"
        )
    )
    .oneOf(
      STATES.map(([_name, abbrev]) => abbrev),
      "Must be a valid state abbrev"
    )
    .required("Can't be blank"),
  zip: yup
    .string()
    // .matches(/^\d\d\d\d\d+$/i, "Must be a valid zip code")
    .required("Can't be blank")
    .when("country", country =>
      yup
        .string()
        .matches(
          country === "CA" ? /^\w\w\w\w\w\w$/ : /^\d\d\d\d\d+$/,
          "Must be a valid zip code"
        )
    ),
  country: yup
    .string()
    .matches(/^(US|CA)$/i, "Use 'CA' for Canada or 'US' for United States")
    .required("Can't be blank"),
});

const Form = ({ _style, theme, navigation }) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    setError,
    watch,
    trigger,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const country = watch("country");

  const inputs = React.useRef({});
  const { setRegistrationValues, registrationValues } =
    useContext(RegistrationContext);

  const handleSubmitError = useCallback(
    error =>
      setError("zip", {
        ...error,
        message: `${error.code} - ${error.message}`,
      }),
    [setError]
  );

  const onSubmit = useCallback(
    ({ address, address2, city, state, zip, country }) => {
      const values = {
        ...registrationValues,
        address,
        address2,
        city,
        state,
        zip,
        country,
      };

      // const birthDatePieces = values.birthDate.split("/")

      setRegistrationValues(registrationValues => ({
        ...registrationValues,
        ...values,
      }));

      return Auth.signUp({
        username: values.email,
        password: values.password,
        attributes: {
          "custom:mobile": `+${values.mobileNumber.replace(/\D+/g, "")}`,
          given_name: values.firstName,
          family_name: values.lastName,
          address: values.address,
          "custom:city": values.city,
          "custom:state": values.state,
          "custom:zip": values.zip,
          "custom:country": values.country,
          birthdate: moment(values.birthDate).format("MM/DD/YYYY"),
          "custom:address2": values.address2 ?? undefined,
          // "custom:referral_code":
          // "custom:bonus_code":
          // "custom:receivePromotions": values.receivePromotions
        },
      })
        .then(_data => [navigation.push("RegistrationConfirmation")])
        .catch(handleSubmitError);
    },
    [navigation, setRegistrationValues, registrationValues, handleSubmitError]
  );

  const doSubmit = handleSubmit(onSubmit);
  // const [showDropDown, setShowDropDown] = React.useState(false);

  React.useEffect(() => {
    !!country && trigger("zip");
    !!country && trigger("state");
  }, [country, trigger]);

  return (
    <>
      <ScrollView
        style={{
          flex: 1,
        }}
      >
        <Controller
          control={control}
          render={({ field: { onChange, value, onBlur } }) => (
            <>
              <TextInput
                autoCorrect={false}
                autoCapitalize="characters"
                ref={e => (inputs.current["country"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "street-address"
                }
                keyboardType="default"
                textContentType="countryName"
                label="Country"
                error={!!errors.country}
                returnKeyLabel="Next"
                returnKeyType="next"
                onSubmitEditing={() => inputs.current.address.focus()}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                maxLength={2}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.country}>
                {errors.country?.message}
              </HelperText>
            </>
          )}
          name="country"
          defaultValue={registrationValues.country}
        />
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <TextInput
                autoCorrect={false}
                autoCapitalize="words"
                ref={e => (inputs.current["address"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "street-address"
                }
                keyboardType="default"
                textContentType="streetAddressLine1"
                label="Address"
                error={!!errors.address}
                returnKeyLabel="Next"
                returnKeyType="next"
                onSubmitEditing={() => inputs.current.address2.focus()}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.address}>
                {errors.address?.message}
              </HelperText>
            </>
          )}
          name="address"
          defaultValue={registrationValues.address}
        />

        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <TextInput
                autoCorrect={false}
                autoCapitalize="words"
                ref={e => (inputs.current["address2"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "street-address"
                }
                keyboardType="default"
                textContentType="streetAddressLine2"
                label="Address 2"
                error={!!errors.address2}
                returnKeyLabel="Next"
                returnKeyType="next"
                onSubmitEditing={() => inputs.current.city.focus()}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.address}>
                {errors.address?.message}
              </HelperText>
            </>
          )}
          name="address2"
          defaultValue={registrationValues.address2}
        />

        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <TextInput
                autoCorrect
                autoCapitalize="words"
                ref={e => (inputs.current["city"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "off"
                }
                keyboardType="default"
                textContentType="addressCity"
                label="City"
                error={!!errors.city}
                returnKeyLabel="Next"
                returnKeyType="next"
                onSubmitEditing={() => inputs.current.city.focus()}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.city}>
                {errors.city?.message}
              </HelperText>
            </>
          )}
          name="city"
          defaultValue={registrationValues.city}
        />

        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <TextInput
                autoCorrect
                autoCapitalize="words"
                ref={e => (inputs.current["state"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "off"
                }
                keyboardType="default"
                textContentType="addressState"
                label={
                  country === "CA"
                    ? "Province (2 letter abbreviation)"
                    : "State (2 letter abbreviation)"
                }
                error={!!errors.state}
                returnKeyLabel="Next"
                returnKeyType="next"
                onSubmitEditing={() => inputs.current.zip.focus()}
                maxLength={2}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value.toUpperCase())}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.state}>
                {errors.state?.message}
              </HelperText>
            </>
          )}
          name="state"
          defaultValue={registrationValues.state}
        />
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <TextInput
                autoCorrect={false}
                autoCapitalize="none"
                ref={e => (inputs.current["zip"] = e)}
                autoCompleteType={
                  Platform.OS === "web" ? "new-password" : "postal-code"
                }
                keyboardType="number-pad"
                textContentType="postalCode"
                label={country === "CA" ? "Postal Code" : "Zip"}
                error={!!errors.zip}
                returnKeyLabel="Done"
                returnKeyType="done"
                onSubmitEditing={doSubmit}
                maxLength={country === "CA" ? 6 : 5}
                mode="flat"
                onBlur={onBlur}
                onChangeText={value => onChange(value)}
                value={value}
                style={{
                  backgroundColor: "transparent",
                }}
              />
              <HelperText type="error" visible={!!errors.zip}>
                {errors.zip?.message}
              </HelperText>
            </>
          )}
          name="zip"
          defaultValue={registrationValues.zip}
        />
      </ScrollView>
      <ScreenBottom theme={theme} adjustPadding>
        <Button
          disabled={!isValid || !!isSubmitting}
          loading={!!isSubmitting}
          mode="contained"
          onPress={doSubmit}
          style={{ width: "100%" }}
        >
          {!!isSubmitting ? "Please Wait..." : "Create Account"}
        </Button>
      </ScreenBottom>
    </>
  );
};

export default ({ navigation }) => {
  const theme = useTheme();

  return (
    <Screen
      gatedTo="guest"
      title="Register"
      hasKeyboard
      backgroundColor={theme.colors.surface}
    >
      <Stepper
        theme={theme}
        currentPosition={3}
        title="Enter Your Address"
        labels={["", "", "", ""]}
      />
      <Form
        style={{ marginTop: theme.spacing.xl }}
        theme={theme}
        navigation={navigation}
      />
    </Screen>
  );
};
