import React from "react";

import { useQuery } from "@apollo/client";
import Bid from "shared/api/Bid";
import { useContext } from "react";
import { CurrentUserContext } from "Contexts/CurrentUser";
import { useTheme } from "react-native-paper";
import BidFormView from "./BidFormView";
import Listing from "shared/api/Listing";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMemo } from "react";
import useStyles from "Hooks/useStyles";
import BidFormStyles from "./BidFormStyles";

export default ({ bidId, action, onSubmit }) => {
  const { data: { getBid } = {} } = useQuery(Bid.queries.get, {
    variables: {
      id: bidId,
    },
  });

  const { data: { getListing } = {} } = useQuery(Listing.queries.get, {
    skip: !getBid?.bidListingId,
    variables: {
      id: getBid?.bidListingId,
    },
  });

  const schema = useMemo(
    () =>
      action !== "COUNTERED"
        ? yup.object().shape({})
        : yup.object().shape({
            counter: yup
              .number()
              .transform(value =>
                isNaN(value) ? undefined : parseFloat(value)
              )
              .moreThan(
                (getBid?.offer ?? 0) / 100.0,
                `Your Counter Offer must be more than the current Bid.`
              )
              .lessThan(
                (getListing?.askingPrice ?? 0) / 100.0,
                `Your Counter Offer must be less than the current Sale Price.`
              )
              .required("Can't be blank"),
          }),
    [getBid, getListing, action]
  );

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isValid,
      isSubmitting,
      touchedFields,
      dirtyFields,
      ...rest
    },
    setError,
    setFocus,
    watch,
    ...others
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const doSubmit = handleSubmit(onSubmit);
  const counter = watch("counter", "");
  const { theme, styles } = useStyles({ Styles: BidFormStyles });
  const currentUser = useContext(CurrentUserContext);

  return currentUser?.id !== getListing?.listingSellerId ? null : (
    <BidFormView
      bid={getBid}
      listing={getListing}
      theme={theme}
      styles={styles}
      action={action}
      onSubmit={doSubmit}
      counter={counter}
      control={control}
      errors={errors}
      isValid={isValid}
      isSubmitting={isSubmitting}
    />
  );
};
