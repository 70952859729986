import React, { useCallback } from "react";
import { List, Divider, useTheme, TouchableRipple } from "react-native-paper";

import Screen from "Components/Screen";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { HeaderButtons, Item } from "react-navigation-header-buttons";
import { Alert, Platform } from "react-native";
import useGidxSession from "Hooks/useGidxSession";
import useGidxDeletePaymentMethod from "Hooks/useGidxDeletePaymentMethod";

export default ({ navigation, route }) => {
  const amount = route?.params?.amount;
  const promotionId = route?.params?.promotionId;
  const theme = useTheme();
  const doDelete = useGidxDeletePaymentMethod();
  const gidxSession = useGidxSession();

  const navigateTo = useCallback(
    (screen, params) => () => [navigation.push(screen, params)],
    [navigation]
  );

  const navigateBackTo = useCallback(
    (screen, params) => () => [navigation.navigate(screen, params)],
    [navigation]
  );

  const handleDelete = useCallback(
    ({ token, type }) =>
      () =>
        Platform.OS === "web"
          ? confirm("Remove this card?") && doDelete({ token, type })
          : Alert.alert(
              "Remove this card?",
              "This will remove the card from your wallet.",
              [
                {
                  text: "Cancel",
                  onPress: () => null,
                  style: "cancel",
                },
                {
                  text: "Delete",
                  onPress: doDelete({ token, type }),
                  style: "destructive",
                },
              ]
            ),
    [doDelete]
  );

  React.useLayoutEffect(() => {
    navigation.setOptions({
      // in your app, you can extract the arrow function into a separate component
      // to avoid creating a new one every time you update the options
      headerRight: () => (
        <HeaderButtons size={48}>
          <Item
            title="Add Card"
            IconComponent={MaterialCommunityIcons}
            iconSize={24}
            iconName="plus"
            onPress={navigateTo("CreditCardAddAccount")}
          />
        </HeaderButtons>
      ),
    });
  }, [navigateTo, navigation]);

  return (
    <Screen
      gatedTo="member"
      title="Select Card"
      fullHeight
      backgroundColor={theme.colors.surface}
    >
      {(gidxSession?.PaymentMethods ?? [])
        .filter(pm => pm.Type === "CC")
        .map(info => (
          <React.Fragment key={info.Token}>
            <List.Item
              title={info.Network}
              description={info.CardNumber}
              right={props => (
                <TouchableRipple
                  onPress={handleDelete({ token: info.Token, type: info.Type })}
                >
                  <List.Icon {...props} icon="trash-can-outline" />
                </TouchableRipple>
              )}
              onPress={navigateBackTo("CreditCardSummary", {
                lastFour: info.Token,
                amount,
                promotionId,
              })}
            />
            <Divider />
          </React.Fragment>
        ))}
    </Screen>
  );
};
