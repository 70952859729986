import BluesnapDepositSummary from "Components/Bluesnap/DepositSummary";

export default ({ navigation, route }) => {
  return (
    <BluesnapDepositSummary
      navigation={navigation}
      type="cc"
      amount={route?.params?.amount ?? "25.00"}
      lastFour={route?.params?.lastFour}
      promotionId={route?.params?.promotionId}
    />
  );
};
