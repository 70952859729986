import React from "react";
import useStyles from "Hooks/useStyles";
import UserItemStyles from "./UserItemStyles";
import UserItemView from "./UserItemView";

export default ({ user, activity }) => {
  const { theme, styles } = useStyles({ Styles: UserItemStyles });

  return (
    <UserItemView
      user={user}
      activity={activity}
      theme={theme}
      styles={styles}
    />
  );
};
